import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import groq from "groq";
import { useDebouncedCallback } from "use-debounce";
import Spin from "../../components/Spin";
import Layout from "../../components/Layout";
import MainImage from "../../components/MainImage";
import GraphQLErrorList from "../../components/GraphQLErrorList";
import sanityClient from "../../services/sanityClient";
import BlogPostsList from "../../components/BlogPostsList";

const Root = styled.div`
  .pagination {
    padding: 10px;
    padding-left: 0;
    a {
      font-size: 22px;
    }
  }
`;

export const query = graphql`
  query blogsQuery {
    posts: allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      limit: 10
    ) {
      edges {
        node {
          id
          title
          excerpt
          mainImage: _rawMainImage
          slug: _rawSlug
          body: _rawBody(resolveReferences: { maxDepth: 10 })
        }
      }
      totalCount
    }
    settings: sanitySiteSettings {
      title
      id
      facebook
      instagram
      twitter
      _rawOpenGraph(resolveReferences: { maxDepth: 10 })
      _rawLogo(resolveReferences: { maxDepth: 10 })
    }
    footer: sanitySiteFooter {
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawLinks(resolveReferences: { maxDepth: 10 })
      _rawSlug(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Blogs = ({ data, errors }) => {
  const [posts, setPosts] = useState(data.posts.edges.map((i) => i.node));
  const [loading, setLoading] = useState(false);
  const [debouncedCallback] = useDebouncedCallback((value) => {
    setLoading(true);
    sanityClient
      .fetch(
        groq`
      *[_type == "post" && title match $query || excerpt match $query || body[].children[].text match $query] | order(publishedAt desc) 
    `,
        { query: `*${value}*` }
      )
      .then((resp) => {
        setPosts(resp);
        setLoading(false);
      });
  }, 250);

  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }
  const onQueryChange = (e) => {
    const { value = "" } = e.target;
    debouncedCallback(value);
  };

  const {
    posts: { totalCount },
    settings,
    footer,
  } = data;

  console.log(totalCount);

  return (
    <Layout
      footer={footer}
      settings={settings}
      title="Text My Gym Blog"
      description="The Text My Gym Blog provides articles, trainings, and free resources for pastors, communications directors, hospitality teams and other gym leaders designed specifically to connect with your community and build relationships"
    >
      <Root>
        <div className="section-spacer">
          <div className="row">
            <div className="col-xs-18 col-md-16 col-md-offset-1 align-justify">
              <h1 className="xl text-blue">
                Communication Resources For Gym Leaders
              </h1>
              <h3>Text My Gym Blog</h3>

              <div className="blog-search">
                <div className="row align-items-center">
                  <div className="col">
                    <input
                      type="text"
                      name="search"
                      onChange={onQueryChange}
                      placeholder="Search the blog..."
                    />
                  </div>
                  <div className="col col-sm-2">
                    <Spin spinning={loading} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-spacer">
          <BlogPostsList posts={posts} />
          {!posts.length ? (
            <div className="row">
              <div className="col-xs-18 col-md-offset-1 col-sm-6 col-md-6" />
              <div className="col-xs-14 col-sm-12 col-md-10">
                <h4>No matching blog found.</h4>
              </div>
            </div>
          ) : null}
        </div>
        <div className="pagination row">
          <div className="col-xs-14 col-sm-12 col-md-10 col-md-offset-7">
            <Link to="/blog/archive">Older Posts</Link>
          </div>
        </div>
      </Root>
    </Layout>
  );
};

export default Blogs;
